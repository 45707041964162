<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 19.879 19.879"
  >
    <path
      :style="iconStyle"
      class="a"
      d="M14.264 9.178h-3.56V5.614a.765.765 0 10-1.526 0v3.564H5.614a.732.732 0 00-.765.765.74.74 0 00.765.765h3.564v3.56a.741.741 0 00.765.765.761.761 0 00.765-.765v-3.56h3.56a.765.765 0 100-1.529z"
    />
    <path
      :style="iconStyle"
      class="a"
      d="M9.939 1.338a8.6 8.6 0 11-6.083 2.518 8.545 8.545 0 016.083-2.518m0-1.338a9.939 9.939 0 109.939 9.939A9.938 9.938 0 009.939 0z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '12.047',
    },
    width: {
      type: String,
      default: '12.047',
    },
    color: {
      type: String,
      default: 'text-color',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
